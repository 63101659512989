<template>
  <div>
    <b-modal
      ref="edit_promo"
      id="edit_promo"
      title="Изменить промокод"
      size="lg"
      @show="getData"
      :no-close-on-backdrop="false"
    >
      <b-row>
        <b-col>
          <b-form-group>
            <label>Промокод<i style="color: red">*</i></label>
            <b-form-input
              id="basicInput"
              placeholder="Наименование"
              v-model="editForm.promo_code"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <label>Выберите тип<i style="color: red">*</i></label>
          <v-select
            :options="typePromocodeUser"
            v-model="editForm.model_type"
            :reduce="(option) => option.id"
            label="name"
            placeholder="Тип"
          />
        </b-col>
        <b-col>
          <label>ID Тип промокода<i style="color: red">*</i></label>
          <v-select
            :reduce="(option) => option.id"
            label="name"
            :options="idTypePromocodes"
            placeholder="Тип"
            v-model="editForm.promocode_type_id"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Организация</label>
          <v-select
            v-model="editForm.organization_id"
            :options="organizations"
            :reduce="(option) => option.id"
            label="name"
            placeholder="Организация"
          />
        </b-col>
        <b-col>
          <label>Подразделение</label>
          <v-select
            v-model="editForm.division_id"
            :options="divisions"
            :reduce="(option) => option.id"
            label="name"
            placeholder="Подразделение"
          />
        </b-col>
        <b-col>
          <label>Кол-во активации</label>
          <b-input
            placeholder="Активации"
            v-model="editForm.count_activation"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Сумма<i style="color: red">*</i></label>
          <b-input placeholder="Сумма" v-model="editForm.amount" />
        </b-col>
        <b-col>
          <label>Дата Началы</label>
          <date-picker
            v-model="editForm.start_time"
            type="datetime-local"
            format="'YYYY-MM-DD HH:mm:ss'"
            :value-type="'YYYY-MM-DD HH:mm:ss'"
            placeholder="YYYY-MM-DD HH:mm:ss"
          ></date-picker>
        </b-col>
        <b-col>
          <label>Дата Окончание</label>
          <date-picker
            v-model="editForm.and_time"
            type="datetime-local"
            format="'YYYY-MM-DD HH:mm:ss'"
            :value-type="'YYYY-MM-DD HH:mm:ss'"
            placeholder="YYYY-MM-DD HH:mm:ss"
          ></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Комментарий</label>
          <b-form-textarea v-model="editForm.comment" placeholder="Комментарий">
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          style="margin-left: 15px; margin-top: 10px"
          class="custom-control custom-checkbox"
        >
          <input
            type="checkbox"
            v-model="editForm.is_active"
            class="custom-control-input"
            id="customCheck1"
          />
          <label class="custom-control-label" for="customCheck1">Статус</label>
        </b-col>
      </b-row>
      <template #modal-footer>
        <button @click="editPromo()" class="btn btn-primary">Изменить</button>
        <b-button @click="closeModal()"> Отмена </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  props: [
    "currentId",
    "idTypePromocodes",
    "typePromocodeUser",
    "organizations",
    "divisions",
  ],
  components: {
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      editForm: {
        promo_code: "",
        model_type: "",
        promocode_type_id: null,
        organization_id: null,
        division_id: null,
        count_activation: "",
        amount: "",
        start_time: null,
        and_time: null,
        comment: "",
        is_active: false,
      },
    };
  },
  methods: {
    getData() {
      this.$http
        .get(`promo-code?filter_id=${this.currentId}&filter_menu=ALL_IN_WORK`)
        .then((res) => {
          if (res.data.length == 0) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка!",
                icon: "XIcon",
                variant: "danger",
                text: `Промокод блокирован или удален`,
              },
            });
            this.closeModal();
            return;
          }
          this.editForm = res.data[0];
        });
    },
    editPromo() {
      this.$http
        .patch(`promo-code/${this.currentId}`, this.editForm)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Промокод изменен`,
            },
          });
          this.closeModal();
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: `Произошла ошибка`,
            },
          });
        });
    },
    closeModal() {
      this.$bvModal.hide("edit_promo");
      this.editForm = {
        promocode_id: "",
        promo_code: "",
        model_type: "",
        promocode_type_id: null,
        organization_id: null,
        division_id: null,
        count_activation: "",
        amount: "",
        start_time: null,
        and_time: null,
        comment: "",
        is_active: false,
      };
    },
  },
};
</script>

<style></style>

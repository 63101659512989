<template>
  <div>
    <b-modal
    ref="delete_promo"
    id="delete_promo"
    title="Удалить промокод"
    size="sm"
    :no-close-on-backdrop = "false"
    >
    <div>
        <label class="label1">Предупреждение!</label>
    </div>
        <br>
    <div>
        <label class="label2">Вы действительно хотите удалить этот промокод.</label>
    </div>
        <template #modal-footer>
            <button class="btn btn-primary"
            @click="deletePromoById"
            >
                Удалить
            </button>
            <b-button
            >
                Отмена
            </b-button>
        </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
export default {
    components:{
        ToastificationContent
    },
    data() {
    return{
        // id: localStorage.getItem('id')
    }
    },
    props:{ 
        id: {
            type: Number,
            default: null,
        },
    },
    methods: {
        deletePromoById(){
            this.$http
            .delete(`promo-code/${this.id}`)
            .then(res =>{
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props : {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Промокод удален`
                    }                    
                })
                this.closeModal()
                this.$emit('refresh')
            })
            .catch(err =>{
            this.$toast({
            component : ToastificationContent, 
            position: 'top-right',
            props: {
                    title: 'Ошибка!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text:` Некоректные данные` 
            }
            })
        })
        },
        closeModal(){
            this.$refs.delete_promo.hide('delete_promo');
            this.deletePromo = '';
        }
    }
}
</script>

<style scoped>
.label1{
    display: flex;
    width: 100%;    
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
}
.label2{
    display: flex;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
</style>
<template>
  <div>
    <b-modal
      :id="$route.name + 'CreateModal'"
      title="Добавить"
      size="lg"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
    >
      <b-row>
        <b-col>
          <b-form-group>
            <label>Добавить промокод<i style="color: red">*</i></label>
            <b-form-input
              v-model="journalPromo.promo_code"
              id="basicInput"
              placeholder="Наименование"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <label>Выберите тип<i style="color: red">*</i></label>
          <v-select
            :reduce="(option) => option.id"
            label="name"
            :options="typePromocodeUser"
            placeholder="Тип"
            v-model="journalPromo.model_type"
          />
        </b-col>
        <b-col>
          <label>ID Тип промокода<i style="color: red">*</i></label>
          <v-select
            :reduce="(option) => option.id"
            label="name"
            :options="idTypePromocodes"
            placeholder="Тип"
            v-model="journalPromo.promocode_type_id"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Организация</label>
          <v-select
            :reduce="(option) => option.id"
            label="name"
            :options="organizations"
            placeholder="Организация"
            v-model="journalPromo.organization_id"
          />
        </b-col>
        <b-col>
          <label>Подразделение</label>
          <v-select
            :reduce="(option) => option.id"
            label="name"
            :options="divisions"
            placeholder="Подразделение"
            v-model="journalPromo.division_id"
          />
        </b-col>
        <b-col>
          <label>Кол-во активации</label>
          <b-input
            placeholder="Активации"
            v-model="journalPromo.count_activation"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Сумма<i style="color: red">*</i></label>
          <b-input placeholder="Сумма" v-model="journalPromo.amount" />
        </b-col>
        <b-col>
          <label>Дата Началы</label>
          <date-picker
            v-model="journalPromo.start_time"
            type="datetime-local"
            format="'YYYY-MM-DD HH:mm:ss'"
            :value-type="'YYYY-MM-DD HH:mm:ss'"
            placeholder="YYYY-MM-DD HH:mm:ss"
          ></date-picker>
        </b-col>
        <b-col>
          <label>Дата Окончание</label>
          <date-picker
            v-model="journalPromo.and_time"
            type="datetime-local"
            format="'YYYY-MM-DD HH:mm:ss'"
            :value-type="'YYYY-MM-DD HH:mm:ss'"
            placeholder="YYYY-MM-DD HH:mm:ss"
          ></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Комментарий</label>
          <b-form-textarea
            v-model="journalPromo.comment"
            placeholder="Комментарий"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          style="margin-left: 15px; margin-top: 10px"
          class="custom-control custom-checkbox"
        >
          <input
            type="checkbox"
            v-model="journalPromo.is_active"
            class="custom-control-input"
            id="customCheck1"
          />
          <label class="custom-control-label" for="customCheck1">Статус</label>
        </b-col>
      </b-row>
      <b-row class="mb-1 mt-2">
        <b-col class="d-flex justify-content-between">
          <b-button @click="closeModal" class="mt-2 col-md-5" variant="danger">
            Отмена
          </b-button>
          <b-button @click="SavePromo" class="mt-2 col-md-5" variant="primary">
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  props: [
    "idTypePromocodes",
    "typePromocodeUser",
    "organizations",
    "divisions",
  ],
  components: {
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      journalPromo: {
        promo_code: "",
        model_type: "",
        promocode_type_id: null,
        organization_id: null,
        division_id: null,
        count_activation: "",
        amount: "",
        start_time: null,
        and_time: null,
        comment: "",
        is_active: false,
      },
    };
  },
  methods: {
    SavePromo() {
      this.$http.post("promo-code", this.journalPromo).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Успех!",
            icon: "CheckIcon",
            variant: "success",
            text: `Промокод добавлен`,
          },
        });
        this.closeModal();
        this.$emit("refresh").catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: ` Некоректные данные`,
            },
          });
        });
      });
    },
    closeModal() {
      this.$bvModal.hide(this.$route.name + "CreateModal");
      this.journalPromo = {
        promo_code: "",
        model_type: "",
        promocode_type_id: null,
        organization_id: null,
        division_id: null,
        count_activation: "",
        amount: "",
        start_time: null,
        and_time: null,
        comment: "",
        is_active: false,
      };
    },
  },
};
</script>
